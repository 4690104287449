.field {
    box-sizing: content-box;
    -ms-box-sizing: content-box;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;

    border-radius: 30px;
    border-style: hidden;

    padding: 10px 20px;
    margin-top: 16px;

    font-size: 16px;
    height: 21px;
    width: 195px;
}

.field:focus {
    outline-width: 0;
}