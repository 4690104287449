.searchbar {
    background-color: #fff;
    border-radius: 30px;

    padding: 7px 10px 4px 10px;
    margin: 15px;
    height: 30px;
}

.searchbar input {
    box-sizing: content-box;
    -ms-box-sizing: content-box;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;

    border-style: hidden;

    font-size: 16px;
    height: 21px;
    width: 195px;
    float:left;
    margin-top: 4px;
}

.searchbar input:focus {
    outline-width: 0;
}