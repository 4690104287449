.table {
    border-radius: 20px;
    width: 70%;
}

.table tr:nth-child(even) {
    background-color: #468;
}

.table tr:nth-child(odd) {
    background-color: #469;
}

.table tr:first-child {
    background-color: #358;
}

.table td {
    padding: 10px;
}

.actions span {
    cursor: pointer;
}

.Running {
    background-color: #0a0
}

.Waiting {
    background-color: #aa0;
}

.Failed {
    background-color: #a00;
}