.page {
    min-height: 75vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-bottom: 30px;
}

.backBtn {
    color: #fff;
    margin: 40px 0 0 10%;
    position: absolute;
    height: 24px;
    width: 24px;
    border: 2px solid #fff;
    border-radius: 15px;
    cursor: pointer;
}

.logo {
    height: 100px;
    margin: 150px 0 -250px;
    background-image: url("../../assets/circle.cloud/circle-cloud-circles-white.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}