.header {
    background-color: rgb(28, 52, 86);
    list-style: none;
    margin-top: 0px;
}

.header > li {
    display: inline-block;
    padding: 20px;
}

.header > li > a {
    text-decoration: none;
    color: #fff;
}