.field {
    box-sizing: content-box;
    -ms-box-sizing: content-box;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;

    border-radius: 30px 0 0 30px;
    border-style: hidden;

    padding: 10px 20px;
    margin-top: 16px;

    font-size: 16px;
    height: 21px;
    width: 195px;
}

.field:focus {
    outline-width: 0;
}

.multiField {
    display: flex;
}

.values {
    list-style: none;
    padding: 0 20px;
    max-height: 200px;
    overflow-y: auto;
}

.values > li {
    font-size: 20px;
    display: flex;
    justify-content: space-between;
}

.values > li > span {
    width: 32px;
    height: 32px;
    vertical-align: bottom;
    cursor: pointer;
}

.add {
    padding: 8px 10px 1.3px 6px;
    border-radius: 0 20px 20px 0;
    cursor: pointer;
    height: 32px;
    margin-top: 15.8px;
    background-color: #f48d32;
}