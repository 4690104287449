.dropdown {
    width: 235px;
}

.dropdown > p {
    background-color: #fff;
    color: #444;
    padding: 10px 20px;
    border-radius: 30px;
    margin-bottom: 5px;
    cursor: pointer;
}

.dropdown > p > span {
    float: right;
    font-size: 30px;
}

.dropdown > ul {
    position: absolute;
    width: 215px;
    margin: 5px;
    margin-top: 0;
    padding: 5px;
    list-style: none;
    background-color: #fff;
    color: #000;
    border-radius: 5px;
    border: 1px solid rgba(50,50,50,0.3);
}

.dropdown > ul > li {
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
}

.dropdown > ul > li:hover {
    background-color: rgba(173, 217, 255, 0.28);
}