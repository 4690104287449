.button {
    box-sizing: content-box;
    -ms-box-sizing: content-box;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    
    height: 40px;
    border-radius: 30px;
    border-style: hidden;
  
    padding: 0;
    width: 235px;
    cursor: pointer;
    color: #fff;
    background-color: rgb(244, 141, 50);
    display: block;
    margin-bottom: 10px;
}