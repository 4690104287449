.borderBox {
    position: relative;
    margin: 20vh auto;
    min-width: 350px;
    min-height: 350px;
    height: 60vh;
    width: 60vw;
    background: linear-gradient(135deg, rgb(200,200,200) 0%, rgba(100,100,100,0.5) 100%);
    border-radius: 20px;
}

.whiteBox {
    position: absolute;
    height: 100%;
    width: 100%;
    top: -6px;
    left: -6px;
    background-color: #fff;
    border-radius: 20px;
}

.circleLogo {
    position: absolute;
    top: 15%;
    left: calc(50% - 150px);
    width: 300px;
    height: 78px;
    background-image: url("../../../assets/circle.cloud/circle-cloud-logo-4.svg");
    background-size: contain;
}

.googleLogin {
    position: absolute;
    top: 50%;
    left: calc(50% - 96.5px);
    margin: auto;
    width: 191px;
    height: 46px;
    background-image: url("../../../assets/google/btn_google_signin_dark_normal_web@2x.png");
    background-size: contain;
    cursor: pointer;
    border-radius: 4px;
}

.googleLogin:hover {
    background-image: url("../../../assets/google/btn_google_signin_dark_focus_web@2x.png");
}

.googleLogin:active {
    background-image: url("../../../assets/google/btn_google_signin_dark_pressed_web@2x.png");
}

/*

.borderBox {
    position: relative;
    margin: calc(20vh - 4px) auto;
    min-width: 350px;
    min-height: 350px;
    height: calc(60vh + 4px);
    width: calc(60vw + 4px);
    background: linear-gradient(135deg, rgb(150,150,150) 0%, rgb(50,50,50) 100%);
    border-radius: 20px;
}

.whiteBox {
    position: absolute;
    height: calc(100% - 4px);
    width: calc(100% - 4px);
    top: 2px;
    left: 2px;
    background-color: #fff;
    border-radius: 20px;
}

old design

*/