.form {
    width: 30%;
    display: flex;
    flex-direction: column;
}

.field, .multiField {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.multiField {
    align-items: start;
}

.select {
    width: 50%;
    color: #000;
}