.status, .error, .success {
    text-align: center;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 5px;
}

.error {
    background-color: #c00;
}

.success {
    background-color: #0c0;
}

.working {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 3px solid rgba(255,255,255,.3);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
    margin: 0 auto;
}
  
@keyframes spin {
    to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    to { -webkit-transform: rotate(360deg); }
}